<template>
	<TableLayout
        :module="module"
        ref="TableLayout"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="name"
        initSortOrder="asc"
        :filters="filters"
        no-oper-buttons>

        <template v-slot:col_dt="{ row, column }">
	    	{{moment(row.value).format('DD.MM.YYYY')}}
	    </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'reference_dates',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
        	module: 'referenceDates',
            tableColumns: [
                {field: 'name', title : 'Name', minWidth: 200, filter: 'input'},
                {field: 'date', title : 'Date', width: 150, slots: { default: 'col_dt' }},
            ],
            filters: {
                name   : { value: undefined },
            }
        }
    },

    computed: {
    	...mapState({
    		user: state => state.app.user,
    	}),

    	rights(){
    		return {
				view : true,
                edit : false,
                add  : false,
                del  : false,
    		}
    	}
    },
}
</script>
